import { useState, useEffect, useCallback } from "react";
import { useTokenRegistryContract } from "../useTokenRegistryContract";
import { EndorsementChain } from "../../../types/index";
import { fetchEscrowTransfers } from "./fetchEscrowTransfer";
import { mergeTransfers } from "./helpers";
import { fetchTokenTransfers } from "./fetchTokenTransfer";
import { getEndorsementChain } from "./retrieveEndorsementChain";
import { retrieveTitleEscrowAddressOnFactory } from "../useTitleEscrowContract";
import { Signer, providers } from "ethers";

export const useEndorsementChain = (
  tokenRegistryAddress: string,
  tokenId: string,
  providerOrSigner: Signer,
  provider: providers.Provider
): {
  endorsementChain?: EndorsementChain;
  pending: boolean;
  error: string;
} => {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");
  const [endorsementChain, setEndorsementChain] = useState<EndorsementChain>();
  const { tokenRegistry } = useTokenRegistryContract(tokenRegistryAddress, providerOrSigner);
  /*
    retrieve transactions from token registry and title escrow events
    merge, sort and provide history of events
  */
  const fetchEndorsementChain = useCallback(async () => {
    //console.log('tokenRegistry', tokenRegistry)
   // console.log('provider', provider)
    //console.log('providerOrSigner', providerOrSigner)
    if (!tokenRegistry || !provider || !providerOrSigner) return;
    //console.log('fetching endorsement chain...')
    setEndorsementChain(undefined);
    setPending(true);
    try {
      const tokenLogs = await fetchTokenTransfers(tokenRegistry, tokenId);
      //console.log('tokenLogs', tokenLogs);
      const escrowAddress = await retrieveTitleEscrowAddressOnFactory(tokenRegistry, tokenId, providerOrSigner);
      //console.log('escrowAddress', escrowAddress);
      const titleEscrowLogs = await fetchEscrowTransfers(provider, escrowAddress);
      //console.log('titleEscrowLogs', titleEscrowLogs);
      const transferEvents = mergeTransfers([...titleEscrowLogs, ...tokenLogs]);
      //console.log('transferEvents', transferEvents);
      const retrievedEndorsementChain = await getEndorsementChain(provider, transferEvents);
      //console.log('retrievedEndorsementChain', retrievedEndorsementChain);
      setEndorsementChain(retrievedEndorsementChain);
    } catch (e: any) {
      console.log(e.message);
    }
    setPending(false);
  }, [provider, providerOrSigner, tokenId, tokenRegistry]);

  useEffect(() => {
    fetchEndorsementChain();
  }, [fetchEndorsementChain]);

  return { endorsementChain, pending, error };
};
