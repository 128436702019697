/* eslint-disable jsx-a11y/anchor-is-valid */
"use client";
import {
  TitleEscrow__factory,
  TradeTrustToken,
  TradeTrustToken__factory,
} from "@govtechsg/token-registry/contracts";
import { useEffect, useState } from "react";
import { IChain } from "../types/chaintype";
import Modal from "./Modal";
import { EndorsementChainLayout } from "./EndorsementChain/EndorsementData";
import { errorAlert, successAlert } from "../utils/alerts";
import { isAddress } from "ethers/lib/utils";
import Loader from "./Loader";
import { getDocumentData } from "../utils/documentData";
import { Signer, providers } from "ethers";
import { useTokenRegistryContract } from "../app/hooks/useTokenRegistryContract";
import { useTitleEscrowContract } from "../app/hooks/useTitleEscrowContract";
import { BurnAddress } from "../configs";
import { useSupportsInterface } from "../app/hooks/useSupportsInterface";
import { ContractFunctionState, useContractFunctionHook } from "@govtechsg/ethers-contract-hook";
import { token } from "@govtechsg/token-registry/dist/contracts/@openzeppelin/contracts";
import { useEndorsementChain } from "../app/hooks/useEndorsementChain";

const ProviderDocumentationURL = "https://docs.tradetrust.io/docs/advanced/add-polygon-networks-to-metamask-wallet/";

declare global {
  interface Window {
    my_modal_1: HTMLFormElement;
  }
}
type IOwner = {
  beneficiary: string;
  holder: string;
  nominee: string;
  surrendered: boolean;
};
type Props = {
  signer: Signer;
  provider: providers.Provider,
  tokenRegistryAddress: string;
  currentChain: IChain;
  walletAddress: string;
  tokenId: string;
};
const DocInfo = ({
  signer,
  provider,
  currentChain,
  tokenRegistryAddress,
  walletAddress,
  tokenId,
}: Props) => {
  const [action, setAction] = useState<
    | "changeHolder"
    | "endorseChangeOwner"
    | "endorseTransfer"
    | "nominateOwner"
    | "surrender"
    | null
  >(null);
  const [newHolder, setNewHolder] = useState("");
  const [newOwner, setNewOwner] = useState("");
  const [titleEscrowAddress, setTitleEscrowAddress] = useState("");
  const [docOwners, setDocOwners] = useState<IOwner | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disableTransfer, setDisableTransfer] = useState(false);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalWidth, setModalWidth] = useState("");

  const { tokenRegistry } = useTokenRegistryContract(tokenRegistryAddress, signer);
  const { titleEscrow, updateTitleEscrow, documentOwner } = useTitleEscrowContract(
    signer,
    tokenRegistry,
    tokenId
  );

  useEffect(() => {
    if (documentOwner) {
      setTitleEscrowAddress(documentOwner)
    }
  }, [documentOwner]);

  useEffect(() => {
    if (tokenRegistry) {
      setIsLoadingInitialData(true)
      // console.log('tokenRegistry', tokenRegistry);
      setTimeout(async () => {
        updateTitleEscrow();
      }, 2000)
    }
  }, [tokenRegistry])
  const isSurrendered = documentOwner === tokenRegistryAddress;
  const isTokenBurnt = documentOwner === BurnAddress; // check if the token belongs to burn address.

  // First check whether Contract is TitleEscrow
  const { isInterfaceType: isTitleEscrow } = useSupportsInterface(titleEscrow, "0x079dff60");

  // Contract Read Functions
  const { call: getHolder, value: holder } = useContractFunctionHook(titleEscrow, "holder");
  const { call: getBeneficiary, value: beneficiary } = useContractFunctionHook(titleEscrow, "beneficiary");
  const { call: getApprovedBeneficiary, value: approvedBeneficiary } = useContractFunctionHook(titleEscrow, "nominee");

  useEffect(() => {
    // console.log('titleEscrow:', titleEscrow);
    // console.log('isTitleEscrow:', isTitleEscrow);
    try {
      if (isTitleEscrow && titleEscrow) {
        // only fetch TitleEscrow info after we determine owner is a Title Escrow contract
        //promisify await for 1 sec
        getHolder();
        getBeneficiary();
        getApprovedBeneficiary();
        // console.log('holder:', holder);
        // console.log('beneficiary:', beneficiary);
        // console.log('approvedBeneficiary/nominee:', approvedBeneficiary);
        if (holder && beneficiary && approvedBeneficiary) {
          setDocOwners({
            beneficiary: beneficiary[0],
            holder: holder[0],
            nominee: approvedBeneficiary[0],
            surrendered: isSurrendered || isTokenBurnt
          })
          //console.log('walletAddress: ', walletAddress);

          setIsLoadingInitialData(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {

    }

  }, [getApprovedBeneficiary, getBeneficiary, getHolder, isTitleEscrow, titleEscrow]);

  const getData = async () => {
    try {
      updateTitleEscrow();
    } catch (error) {
      console.error(error);
    }
  };
  const endorsementChainProps = useEndorsementChain(tokenRegistryAddress, tokenId, signer, provider);
  const { error, pending } = endorsementChainProps;

  useEffect(() => {
    //console.log('error', error);
    // console.log('pending', pending);
  }, [error, pending])
  const OwnerAndHolder = () => {
    return (
      <>
        <li>
          <a onClick={handleTransferHolder}>Transfer Holdership</a>
        </li>
        <li>
          <a onClick={handleTransferOwner}>Endorse Change of Ownership</a>
        </li>
        <li>
          <a onClick={handleSurrender}>Surrender Document</a>
        </li>
      </>
    );
  };
  const HolderAndNominee = () => {
    return (
      <>
        <li>
          <a onClick={handleTransferHolder}>Transfer Holdership</a>
        </li>
        <li>
          <a onClick={handleEndorseChangeOwner}>
            Endorse Transfer of Ownership
          </a>
        </li>
      </>
    );
  };
  const OnlyHolder = () => {
    return (
      <li>
        <a onClick={handleTransferHolder}>Transfer Holdership</a>
      </li>
    );
  };
  const OnlyOwner = () => {
    return (
      <li>
        <a onClick={handleNominateOwner}>Nominate Change of Ownership</a>
      </li>
    );
  };

  const nominateOwnership = async () => {
    try {
      if (!signer) {
        errorAlert("Signer not found!");
        return;
      }
      if (!newOwner || newOwner.trim() === "") {
        errorAlert("Please enter a owner address!");
        return;
      }
      if (!isAddress(newOwner)) {
        errorAlert("Please enter a valid ethereum address!");
        return;
      }
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer
      );
      await connectedEscrow.nominate(newOwner);
      successAlert("Document has been nominated successfully!");
      const template = (
        <div className="mt-5">
          <p className="text-sm">
            Document has been nominated successfully. <br /> Please notify
            holder to execute transfer.
          </p>
        </div>
      );
      handleModalOpen("Nomination Success", template, "w-1/3");
      getData();
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const transferHolder = async () => {
    try {
      if (!signer) {
        errorAlert("Signer not found!");
        return;
      }
      if (!newHolder || newHolder.trim() === "") {
        errorAlert("Please enter a holder address!");
        return;
      }
      if (!isAddress(newHolder)) {
        errorAlert("Please enter a valid ethereum address!");
        return;
      }
      setIsLoading(true);
      //console.log("transferring holder...");
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer
      );
      await connectedEscrow.transferHolder(newHolder);
      successAlert("Holdership Transfered Successfully!");
      const template = (
        <div className="mt-5">
          <p className="text-sm">Current Holder</p>
          <p className="text-xl">{newHolder}</p>
        </div>
      );
      handleModalOpen("Transfer Holder Successfull", template, "w-1/3");
      getData();
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const transferOwners = async () => {
    if (!signer) {
      errorAlert("Signer not found!");
      return;
    }
    try {
      if (!newHolder || newHolder.trim() === "") {
        errorAlert("Please enter a holder address!");
        return;
      }
      if (!newOwner || newOwner.trim() === "") {
        errorAlert("Please enter a owner address!");
        return;
      }
      if (!isAddress(newOwner)) {
        errorAlert("Please enter a valid owner address!");
        return;
      }
      if (!isAddress(newHolder)) {
        errorAlert("Please enter a valid holder address!");
        return;
      }
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer
      );
      await connectedEscrow.transferOwners(newOwner, newHolder);
      successAlert("Endorse Transfered Successfully!");
      const template = (
        <div className="mt-5">
          <p className="text-sm">Current Owner</p>
          <p className="text-xl">{newOwner}</p>
          <p className="text-sm mt-5">Current Holder</p>
          <p className="text-xl">{newHolder}</p>
        </div>
      );
      handleModalOpen(
        "Endorce Ownership / Holdership Transfered Successfully",
        template,
        "w-1/3"
      );
      getData();
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const surrenderOwnership = async () => {
    try {
      if (!signer) {
        errorAlert("Signer not found!");
        return;
      }
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer
      );
      await connectedEscrow.surrender();
      successAlert("Document Surrendered Successfully!");
      const template = (
        <div className="mt-5">
          <p className="text-sm">Surrender Document Success</p>
          <p className="text-xl">
            This Bill of Lading has been surrendered, pending acceptance of
            issuer.
          </p>
        </div>
      );
      handleModalOpen(
        "Endorce Ownership / Holdership Transfered Successfully",
        template,
        "w-1/3"
      );
      getData();
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };
  const endorseChangeOwnership = async () => {
    try {
      if (!signer) {
        errorAlert("Signer not found!");
        return;
      }
      if (!docOwners?.nominee || docOwners.nominee.trim() === "") {
        errorAlert("Nominee not found!");
        return;
      }
      setIsLoading(true);
      const connectedEscrow = TitleEscrow__factory.connect(
        titleEscrowAddress,
        signer
      );
      await connectedEscrow.transferBeneficiary(docOwners?.nominee!);
      successAlert("Beneficiary Transfered Successfully!");
      const template = (
        <div className="mt-5">
          <p className="text-sm">Current Beneficiary</p>
          <p className="text-xl">{docOwners?.nominee}</p>
        </div>
      );
      handleModalOpen(" Beneficiary Updated Successfully", template, "w-1/3");
      getData();
    } catch (error: any) {
      console.error(error);
      errorAlert(error.message);
    } finally {
      reset();
    }
  };

  const handleEndorseChangeOwner = () => {
    setAction("endorseChangeOwner");
  };
  const handleNominateOwner = () => {
    setAction("nominateOwner");
  };
  const handleTransferOwner = () => {
    setAction("endorseTransfer");
  };
  const handleTransferHolder = () => {
    setAction("changeHolder");
  };
  const handleSurrender = () => {
    setAction("surrender");
  };
  const handleTransfer = () => {
    if (action === "changeHolder") {
      transferHolder();
    } else if (action === "endorseTransfer") {
      transferOwners();
    } else if (action === "nominateOwner") {
      nominateOwnership();
    }
  };
  const handleModalOpen = (
    title: string,
    content: any,
    modalWidth: string = "w-11/12"
  ) => {
    setModalTitle(title);
    setModalContent(content);
    setModalWidth(modalWidth);
    (document.getElementById("my_modal_1") as HTMLDialogElement)?.showModal();
  };

  const reset = () => {
    setNewHolder("");
    setNewOwner("");
    setIsLoading(false);
    setAction(null);
  };
  useEffect(() => {
    if (
      action === "changeHolder" &&
      (newHolder.trim() === "" ||
        !isAddress(newHolder) ||
        newHolder === docOwners?.holder)
    ) {
      setDisableTransfer(true);
    } else if (
      action === "endorseTransfer" &&
      (newHolder.trim() === "" ||
        newOwner.trim() === "" ||
        !isAddress(newHolder) ||
        !isAddress(newOwner) ||
        newHolder === docOwners?.holder)
    ) {
      setDisableTransfer(true);
    } else if (
      action === "nominateOwner" &&
      (newOwner.trim() === "" || !isAddress(newOwner))
    ) {
      setDisableTransfer(true);
    } else {
      setDisableTransfer(false);
    }
  }, [action, newOwner, newHolder]);

  // console.log("currentChain: ", currentChain);
  return (
    <>
      <Modal title={modalTitle} modalWidth={modalWidth}>
        {modalContent}
      </Modal>

      <div className="flex flex-col md:flex-row items-start justify-between gap-5 my-10 bg-neutral-50 shadow shadow-neutral-500 rounded-lg px-10 py-20 w-full">
        <div className="flex flex-col items-start gap-3">
          <p className="font-bold text-xl text-gray-800">Nft Information:</p>
          <a
            target="_blank"
            className="font-bold text-blue-500 link link-hover"
            href={currentChain?.explorerUrl + `/address/` + tokenRegistryAddress}
            rel="noreferrer"
          >
            View NFT Registry
          </a>
          <a
            className="font-bold text-blue-500 link link-hover"
            onClick={() =>
              handleModalOpen("Endorsement Chain", <EndorsementChainLayout {...endorsementChainProps} providerDocumentationURL={ProviderDocumentationURL} />)
            }
          >
            View Endorsement Chain
          </a>
        </div>
        {docOwners?.surrendered === true ? (
          <div className="flex flex-col items-end gap-4">
            <p className="font-bold text-4xl border-2 border-red-500 text-red-500 rounded-lg p-2">
              Surrendered To Issuer
            </p>
            <button className="btn bg-blue-500 text-white">No Access</button>
          </div>
        ) : (
          <>
            <div className="flex flex-col items-start gap-3">
              {isLoadingInitialData ? (
                <div className="flex flex-col gap-4 w-96">
                  <div className="skeleton h-6 w-full"></div>
                  <div className="skeleton h-6 w-full"></div>
                </div>
              ) : (
                <>
                  <p className="font-bold text-xl text-gray-500">
                    {action === "endorseChangeOwner" && docOwners?.nominee
                      ? "Nominee:"
                      : "Owner:"}
                  </p>
                  <a
                    target="_blank"
                    href={
                      currentChain?.explorerUrl +
                      `/address/` +
                      (action === "endorseChangeOwner" && docOwners?.nominee
                        ? docOwners?.nominee
                        : docOwners?.beneficiary)
                    }
                    className="font-bold text-blue-500 link link-hover break-all"
                    rel="noreferrer"
                  >
                    {action === "endorseChangeOwner" && docOwners?.nominee
                      ? docOwners?.nominee
                      : docOwners?.beneficiary}
                  </a>
                </>
              )}

              {action === "endorseTransfer" || action === "nominateOwner" ? (
                <input
                  id="ownerAddress"
                  name="ownerAddress"
                  type="text"
                  className="input input-bordered w-full"
                  placeholder="Enter New Owner's address"
                  value={newOwner}
                  onChange={(e) => setNewOwner(e.target.value)}
                />
              ) : null}
            </div>
            <div className="flex flex-col items-start gap-3">
              {isLoadingInitialData ? (
                <div className="flex flex-col gap-4 w-96">
                  <div className="skeleton h-6 w-full"></div>
                  <div className="skeleton h-6 w-full"></div>
                </div>
              ) : (
                <>
                  <a className="font-bold text-xl text-gray-500">Holder: </a>
                  <a
                    target="_blank"
                    href={
                      currentChain?.explorerUrl +
                      `/address/` +
                      docOwners?.holder
                    }
                    className="font-bold text-blue-500 link link-hover break-all"
                    rel="noreferrer"
                  >
                    {docOwners?.holder}
                  </a>
                </>
              )}

              {action === "changeHolder" || action === "endorseTransfer" ? (
                <input
                  id="holderAddress"
                  name="holderAddress"
                  type="text"
                  value={newHolder}
                  className="input input-bordered w-full"
                  placeholder="Enter New Holder's address"
                  onChange={(e) => setNewHolder(e.target.value)}
                />
              ) : null}
            </div>
            <div className="">
              {walletAddress?.toLowerCase() === docOwners?.beneficiary?.toLowerCase() ||
                walletAddress?.toLowerCase() === docOwners?.holder?.toLowerCase() ? (
                <>
                  {action ? (
                    <div className="flex flex-col gap-4 w-full">
                      {action === "surrender" ? (
                        <button
                          className="btn bg-red-500 text-white flex items-center gap-4"
                          onClick={surrenderOwnership}
                          disabled={isLoading}
                        >
                          Surrender
                          {isLoading && <Loader />}
                        </button>
                      ) : action === "endorseChangeOwner" ? (
                        <button
                          className="btn bg-green-500 text-white flex items-center gap-4"
                          onClick={endorseChangeOwnership}
                          disabled={isLoading}
                        >
                          Endorse
                          {isLoading && <Loader />}
                        </button>
                      ) : (
                        <button
                          className="btn bg-green-500 text-white flex items-center gap-4"
                          onClick={handleTransfer}
                          disabled={isLoading || disableTransfer}
                        >
                          Transfer
                          {isLoading && <Loader />}
                        </button>
                      )}
                      <button
                        onClick={() => reset()}
                        className="btn btn-outline btn-error px-10"
                        disabled={isLoading}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className="dropdown">
                      <div
                        tabIndex={0}
                        role="button"
                        className="btn bg-blue-500 text-white m-1"
                      >
                        Manage Assets
                      </div>
                      <ul
                        tabIndex={0}
                        className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-64 "
                      >
                        {walletAddress?.toLowerCase() === docOwners?.beneficiary?.toLowerCase() &&
                          walletAddress?.toLowerCase() === docOwners?.holder?.toLowerCase() ? (
                          <OwnerAndHolder />
                        ) : walletAddress?.toLowerCase() === docOwners?.holder?.toLowerCase() &&
                          walletAddress?.toLowerCase() === docOwners.nominee?.toLowerCase() ? (
                          <HolderAndNominee />
                        ) : walletAddress?.toLowerCase() === docOwners?.holder?.toLowerCase() ? (
                          <OnlyHolder />
                        ) : walletAddress?.toLowerCase() === docOwners?.beneficiary?.toLowerCase() ? (
                          <OnlyOwner />
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  )}
                </>
              ) : (
                <button className="btn">No Access</button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DocInfo;
